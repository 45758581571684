<template>
  <div>
    <b-row class="px-2">
      <b-col class="mb-2" cols="4">
        <h1 class="mb-0">Remessa detalhada</h1>
      </b-col>

      <b-col class="d-flex justify-content-end align-items-end mb-2" cols="8">
        <b-button
          variant="outline-secondary"
          class="d-flex justify-content-between align-items-center bg-white"
          @click="$router.push({ name: 'remessas.index' })"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-1" />
          <span class="text-dark font-weight-bolder"> Voltar </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3" lg="3" md="6" class="mb-0">
        <b-card class="control-border-color mb-2">
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span class="mr-2"> Número </span>
            <h5
              class="mb-0 ml-2 font-weight-bolder truncate-text"
              v-b-tooltip.hover
              :title="remessa.numero"
            >

              {{ remessa.numero }}</h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Cedente </span>
            <h5
              class="mb-0 ml-2 font-weight-bolder truncate-text"
              v-b-tooltip.hover
              :title="remessa.cedente?.nome"
            >
              {{ remessa.cedente?.nome }}
              <!-- {{ truncateWithEllipsis(remessa.cedente?.nome, 'end', 10) }} -->
            </h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Data de envio </span>
            <h5 class="mb-0 font-weight-bolder">{{ remessa.created_at | dateFormat }}</h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Data da operação </span>
            <h5 class="mb-0 font-weight-bolder">{{ remessa.data_resgate | dateFormat }}</h5>
          </div>
          <div class="fz-16 d-flex justify-content-between align-items-center">
            <span> Status </span>
            <div
              v-if="remessa.status != 'cancelada'"
              v-html="remessaStatusBadge(remessa.status)"
            ></div>
            <div v-else class="d-flex justify-content-end align-items-center">
              <span v-html="remessaStatusBadge(remessa.status)" class="mr-1"></span>
              <feather-icon
                icon="AlertCircleIcon"
                class="text-danger"
                size="22"
                v-b-tooltip.hover
                :title="remessa.motivo_cancelamento"
              />
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" class="d-flex flex-column justify-content-between">
        <b-card class="control-border-color mb-1">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Total de face </span>
            <h3 class="mb-0 font-weight-bolder">{{ remessa.total_valor_face | moneyFormat }}</h3>
          </div>
        </b-card>
        <b-card class="control-border-color mb-2">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Total operado </span>
            <h3 class="mb-0 font-weight-bolder">{{ remessa.total_valor_operado | moneyFormat }}</h3>
          </div>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" class="d-flex flex-column justify-content-between">
        <b-card class="control-border-color mb-1">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <p class="mb-0">Taxa média</p>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0 font-weight-bolder">{{ remessa.taxa_media || '0' }}</h3>
              <p class="mb-0 ml-25">%</p>
            </div>
          </div>
        </b-card>
        <b-card class="control-border-color mb-2">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Prazo médio </span>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0 font-weight-bolder">{{ remessa.prazo_medio || '0' }}</h3>
              <p class="mb-0 ml-25">
                {{ remessa.prazo_medio > 1 ? 'dias' : !remessa.prazo_medio ? '' : 'dia' }}
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" class="d-flex flex-column justify-content-between">
        <b-card class="control-border-color mb-1">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Quantidade de títulos </span>
            <h3 class="mb-0 font-weight-bolder">{{ remessa.quantidade_titulos || '0' }}</h3>
          </div>
        </b-card>
        <b-card class="control-border-color mb-2">
          <div class="fz-16 d-flex justify-content-between align-items-center py-50">
            <span> Títulos rejeitadoss </span>
            <h3 class="mb-0 font-weight-bolder">{{ remessa.quantidade_titulos_rejeitados || '0' }}</h3>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <datatable
      ref="titlesDatatable"
      :serviceUrl="serviceUrl"
      v-if="serviceUrl"
      :customFields="customFields"
      showExportToExcell
      :exportToExcellAction="downloadTitlesXlsx"
    >
      <template #table-header-actions>
        <div class="d-flex justify-content-start align-items-center">
          <!-- <b-button @click="downloadTitlesXlsx()" variant="flat-secondary" class="text-nowrap mr-2">
            Exportar
            <img
              class="img-icon text-secondary ml-50"
              height="17"
              width="16"
              src="@/assets/images/export_icon.svg"
            />
          </b-button> -->
          <flat-pickr
            v-model="selectedDate"
            class="assignor-selector form-control bg-white mr-1"
            :config="config"
            placeholder="Data de vencimento"
          />
          <v-select
            v-model="selectedStatus"
            :options="statuses"
            class="assignor-selector d-flex flex-column mr-2"
            placeholder="Status"
          >
          </v-select>
        </div>
      </template>
    </datatable>
  </div>
</template>

<script>
import { BCol, BRow, BCard, BButton, VBTooltip } from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Datatable from '@/views/common/crud/components/Datatable'

import {
  tituloStatusImportacaoBadge,
  remessaStatusBadge,
  moneyFormatDatatable,
  brazilianFormattedDate,
} from '@core/comp-functions/data_visualization/datatable'

import { truncateWithEllipsis } from '@core/comp-functions/data_visualization/string'
import downloadFile from '@/utils/download-file'

export default {
  name: 'ShowConcession',
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    vSelect,
    flatPickr,
    Datatable,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    moneyFormat: moneyFormatDatatable,
    dateFormat: brazilianFormattedDate,
    truncateWithEllipsis,
  },
  data() {
    return {
      serviceUrl: '',
      remessa: {},
      selectedDate: '',
      selectedStatus: null,
      statuses: [
        { value: 'pendente', label: 'Pendente' },
        { value: 'importado', label: 'Importado' },
        { value: 'rejeitado', label: 'Rejeitado' },
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      customFields: [
        {
          label: 'Número',
          key: 'numero',
          sortable: true,
        },
        {
          label: 'Sacado',
          key: 'sacado',
          sortable: true,
          tdClass: 'truncate-text',
        },
        {
          label: 'Emissão',
          key: 'data_emissao',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Vencimento',
          key: 'data_vencimento',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Valor de face',
          key: 'valor_face',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor operado',
          key: 'valor_operado',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Dias operados',
          key: 'prazo',
          sortable: true,
        },
        {
          label: 'Tipo',
          key: 'descricao_tipo',
          sortable: true,
        },
        {
          label: 'Status',
          key: 'status_importacao',
          formatter: tituloStatusImportacaoBadge,
        },
        {
          label: '',
          key: 'copy',
          sortable: false,
        },
      ],
    }
  },
  async mounted() {
    this.serviceUrl = `/recebiveis/remessas/${this.$route.params.id}/titulos`
    await this.getRemessa()
  },
  methods: {
    truncateWithEllipsis,
    remessaStatusBadge,
    async getRemessa() {
      const data = await this.$store.dispatch('admin/getRemessa', this.$route.params.id)
      this.remessa = data
    },
    async sendToSignConfirm() {
      this.$swal({
        title: 'Enviando remessa para assinatura',
        text: 'Esta ação não poderá ser revertida. Você tem certeza que deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Enviar para assinatura',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.sendToSign()
        }
      })
    },
    async sendToSign() {
      try {
        await this.$store.dispatch('admin/sendToSign', this.$route.params.id)
        this.$swal({
          title: 'Remessa enviada para assinatura',
          text: 'A remessa foi enviada para assinatura com sucesso',
          icon: 'success',
        })
        this.getRemessa()
      } catch (error) {}
    },
    async sendToPayConfirm() {
      this.$swal({
        title: 'Informando pagamento de remessa',
        text: 'Esta ação não poderá ser revertida. Você tem certeza que deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Informar pagamento',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.sendToPay()
        }
      })
    },
    async sendToPay() {
      try {
        await this.$store.dispatch('admin/sendToPay', this.$route.params.id)
        this.$swal({
          title: 'Pagamento informado',
          text: 'O pagamento foi informado com sucesso',
          icon: 'success',
        })
        this.getRemessa()
      } catch (error) {}
    },
    async cancelRemessaConfirm() {
      this.$swal({
        title: 'Cancelar remessa',
        text: 'Esta ação não poderá ser revertida. Você tem certeza que deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Cancelar remessa',
        cancelButtonText: 'Voltar',
      }).then((result) => {
        if (result.value) {
          this.cancelRemessa()
        }
      })
    },
    async cancelRemessa() {
      try {
        await this.$store.dispatch('admin/cancelRemessa', this.$route.params.id)
        this.$swal({
          title: 'Remessa cancelada',
          text: 'A remessa foi cancelada com sucesso',
          icon: 'success',
        })
        this.getRemessa()
      } catch (error) {}
    },
    async downloadTitlesXlsx() {
      try {
        const { data } = await this.$store.dispatch(
          'admin/exportTitles',
          Object.assign(this.$refs.titlesDatatable.params, { remessa_id: this.remessa.id }),
        )
        downloadFile(data, 'titulos.xlsx', 'text/xlsx')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Ocorreu um erro ao tentar baixa o arquivo.',
        })
      }
    },
  },
  computed: {
    signable() {
      return ['disponivel'].includes(this.remessa.status)
    },
    payable() {
      return ['assinada'].includes(this.remessa.status)
    },
    cancelable() {
      return ['disponivel'].includes(this.remessa.status)
    },
  },
  watch: {
    selectedAssignor(newV) {
      const payload = newV ? { cedente_id: newV.id } : {}
      this.$refs.titlesDatatable.updateFilterValues(payload)
    },
    selectedStatus(newV) {
      const payload = newV ? { status_importacao: newV.value } : {}
      this.$refs.titlesDatatable.updateFilterValues(payload)
    },
    selectedDate(newV) {
      let payload = {}

      if (newV.includes(' até ')) {
        const [inicio, fim] = newV.split(' até ')
        payload = {
          data_vencimento_de: inicio,
          data_vencimento_ate: fim,
        }
      } else {
        payload = newV ? { data_inicio: newV } : {}
      }

      this.$refs.titlesDatatable.updateFilterValues(payload)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.fz-16 {
  font-size: 16px;
  line-height: 24px;
}

.control-border-color {
  .card-body {
    border: 1px solid #dae1e8;
    border-radius: 6px;
  }
}

.control-border-color .card-body {
  padding: 14px 16px 14px 16px !important;
}

.mw-259 {
  min-width: 259px;
}

.assignor-selector {
  min-width: 235px;
}
</style>
